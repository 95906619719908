import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import "./Loading.css";

const Loading = ({ fontSize = "xx-large", color = "var(--white-color)" }) => {
  return (
    <>
      <div className="f-1 f-center of-hidden">
        <FontAwesomeIcon
          className="loading fast-spin"
          icon={solid("circle-notch")}
          style={{ fontSize, color }}
        />
      </div>
    </>
  );
};

export default Loading;
