import React, { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";
import Home from "screens/Home";
import Main from "screens/Main";
import Lorem from "screens/Lorem";
import Characters from "screens/characters/Characters";
import Media from "screens/Media";
import useScrollbarSize from "react-scrollbar-size";

import "./App.css";
import Buy from "screens/Buy";
import Learn from "screens/Learn";
import Book from "screens/Book";

const App = () => {
  const { width } = useScrollbarSize();

  useEffect(() => {
    document.documentElement.style.setProperty("--scroll-width", `${width}px`);
  }, [width]);

  return (
    <ParallaxProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />}>
            <Route index element={<Home />} />
            <Route path="characters" element={<Characters />} />
            <Route path="media" element={<Media />} />
            {/* <Route path="News" element={<Lorem />}/> */}
            <Route path="learn" element={<Learn />} />
            <Route path="buy" element={<Buy />} />
            <Route path="book" element={<Book />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ParallaxProvider>
  );
};

export default App;
