import { useEffect, useRef, useState } from "react";
import NavBar from "rxs/components/NavBar";

import "./NavBarBottom.css";

const NavBarBottom = ({
  onStickyChanged = v => {},
  forceSticky = false,
  navBarHeight = "85px",
  ...props
}) => {
  const ref = useRef();
  const navBarRef = useRef();

  const [sticky, setSticky] = useState(false || forceSticky);

  useEffect(() => {
    onStickyChanged(sticky);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sticky]);

  useEffect(() => {
    if (forceSticky) {
      setSticky(true);
    }
  }, [forceSticky]);

  useEffect(() => {
    const updatePosition = () => {
      if (forceSticky) {
        return;
      }
      if (window.pageYOffset > ref.current.offsetTop) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };
    window.addEventListener("scroll", updatePosition);
    updatePosition();
    return () => window.removeEventListener("scroll", updatePosition);
  }, [forceSticky]);

  return (
    <>
      <div ref={ref} style={{ position: "absolute", height: navBarHeight }} />
      <NavBar
        ref={navBarRef}
        containerClassName={sticky ? "sticky" : ""}
        {...props}
      />
    </>
  );
};

export default NavBarBottom;
