import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Backdrop from "components/Backdrop";
import commands from "images/OmenOfSorrow_CommandList.pdf";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Document, Page } from "react-pdf";
import WideContainer from "rxs/components/WideContainer";
import useMobile from "rxs/hooks/useMobile";
import "./News.css";

// import poster from "images/news/taito-omen.jpg";
import poster from "images/news/taito-launch.jpg";

import Loading from "rxs/components/Loading";
import useWindowSize from "rxs/hooks/useWindowSize";
import { Erzbeth, Thalessa } from "screens/characters/Torso";

// const posterSize = { width: 1361, height: 1920 };
const posterSize = { width: 1920, height: 1080 };

const News = () => {
  const backdropRef = useRef();
  const contentRef = useRef();
  const { t, i18n } = useTranslation();

  const [contentScrollbarVisible, setContentScrollbarVisible] = useState(false);

  const checkContentScrollBar = () => {
    setContentScrollbarVisible(
      contentRef.current?.scrollHeight > contentRef.current?.clientHeight
    );
  };

  useEffect(() => {
    window.addEventListener("resize", checkContentScrollBar);
    setTimeout(checkContentScrollBar, 100);
    checkContentScrollBar();
    return () => window.removeEventListener("resize", checkContentScrollBar);
  }, []);

  const mobile = useMobile();

  const [windowsWidth, windowHeight] = useWindowSize();

  return (
    <>
      <Backdrop ref={backdropRef} showOnStart>
        <WideContainer
          className="news m-48 p-32 of-auto"
          maxWidth="1024px"
          containerClassName="of-auto"
        >
          <div className="fs-32 f-center pb-16 p-relative">
            <div className="fw-bold">News</div>

            <div
              className="p-absolute c-pointer r-0"
              onClick={() => backdropRef.current.hide()}
            >
              <FontAwesomeIcon className="zi-2 fs-32" icon={solid("xmark")} />
            </div>
          </div>
          <div className="line" />
          <div
            ref={contentRef}
            className={`col-m16 of-auto ${
              contentScrollbarVisible ? "pr-16" : ""
            }`}
          >
            <div />
            {/* <ArcadeNews /> */}
            {/* <TaitoNews /> */}
            <PatchNews />
          </div>
        </WideContainer>
      </Backdrop>

      <div
        style={{
          position: "fixed",
          bottom: 0,
          right: 0,
          display: "block",
          zIndex: 1000,
          padding: 32,
        }}
      >
        <div
          className="button p-relative c-pointer"
          onClick={() => backdropRef.current.show()}
        >
          <FontAwesomeIcon className="fs-32" icon={solid("newspaper")} />

          <div
            className="notification"
            style={{
              position: "absolute",
              right: -4,
              top: -4,
            }}
          >
            <div>1</div>
          </div>
        </div>
      </div>
    </>
  );
};

const Info = ({ className, title, children }) => {
  const { i18n } = useTranslation();

  return (
    <div>
      <div
        className={`fs-18 py-8 ${
          i18n.language === "en" ? "fw-bold" : ""
        } ${className}`}
      >
        {title}
      </div>
      <div className="ta-justify">{children}</div>
    </div>
  );
};

const Store = ({ index = 1 }) => {
  const { t } = useTranslation();

  return (
    <div className="store mb-16">
      <div className="store-name fs-20 my-8">{t(`taitoStore${index}`)}</div>
      <div>
        {t("address")}: {t(`taitoStore${index}Address`)}
      </div>
      <div>
        {t("phone")}: {t(`taitoStore${index}Phone`)}
      </div>
      <span>
        {t("web")}:{" "}
        <a href={t(`taitoStore${index}Web`)}> {t(`taitoStore${index}Web`)} </a>
      </span>
    </div>
  );
};

const ArcadeNews = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      {i18n.language === "en" && (
        <Info title={t("taitoOmenTitle")}>
          {t("taitoOmen1")} {"\n"} {t("taitoOmen2")}
        </Info>
      )}
      {i18n.language === "jp" && (
        <>
          <div className="ta-center">{t("taitoOmenTitle")}</div>
          <div>{t("taitoOmen1")}</div>
          <div>{t("taitoOmen2")}</div>
        </>
      )}

      <Info title={t("taitoInfoTitle")}>{t("taitoInfoContent")}</Info>

      <div />
      <Store index={1} />
      <Store index={2} />
      <Store index={3} />

      <Info title={t("taitoNesicaTitle")}>{t("taitoNesicaContent")}</Info>

      <Info title={t("taitoUrlTitle")}>
        <span className="ta-left">
          {t("taitoUrl1Content")}:{" "}
          <a href={t("taitoUrl1Url")}> {t("taitoUrl1Url")} </a>
        </span>
        <span className="ta-left">
          {t("taitoUrl2Content")}:{" "}
          <a href={t("taitoUrl2Url")}> {t("taitoUrl2Url")} </a>
        </span>
      </Info>
    </>
  );
};

const TaitoNews = () => {
  const { t } = useTranslation();
  const mobile = useMobile();
  const [windowsWidth, windowHeight] = useWindowSize();

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const posterRef = useRef();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const getWidth = media => {
    const diffX = mobile ? 0 : 128;
    const diffY = mobile ? 48 : 128;

    if (media.width > media.height) {
      let width = windowsWidth - diffX;
      let height = (width / media.width) * media.height;
      if (height + diffY > windowHeight) {
        height = windowHeight - diffY;
        width = (height / media.height) * media.width;
      }

      return width;
    } else {
      let height = windowHeight - diffY;
      let width = (height / media.height) * media.width;
      if (width + diffX > windowsWidth) {
        width = windowsWidth - diffX;
        height = (width / media.width) * media.height;
      }
      return width;
    }
  };

  const getHeight = media => {
    const diffX = mobile ? 0 : 128;
    const diffY = mobile ? 48 : 128;

    if (media.width > media.height) {
      let width = windowsWidth - diffX;
      let height = (width / media.width) * media.height;
      if (height + diffY > windowHeight) {
        height = windowHeight - diffY;
        width = (height / media.height) * media.width;
      }

      return height;
    } else {
      let height = windowHeight - diffY;
      let width = (height / media.height) * media.width;
      if (width + diffX > windowsWidth) {
        width = windowsWidth - diffX;
        height = (width / media.width) * media.height;
      }
      return height;
    }
  };

  return (
    <>
      <Info
        title={t("taitoLaunch")}
        className={`ta-center ${mobile ? "fs-12" : "fs-24"}`}
      />

      <img
        className="as-center c-pointer"
        onClick={() => posterRef.current.show()}
        src={poster}
        alt="poster"
        style={{
          // width: `${(50 * posterSize.width) / posterSize.height}vh`,
          width: `100%`,
        }}
      />

      <a
        // href="https://www.taito.co.jp/mob/topics/25532"
        href="https://www.taito.co.jp/mob/topics/25656"
        target="_blank"
        rel="noopener noreferrer"
        className="as-center bg-accent p-16 fw-bold fs-18 ta-center"
        style={{
          color: "white",
          boxShadow: "0px 0px 8px 2px rgba(255, 255, 255, 0.3)",
        }}
      >
        {t("moreInfo")}
      </a>
      <div
        style={{
          minHeight: Math.min(
            windowHeight - 250,
            ((windowsWidth - 32) / 3) * 4
          ),
          minWidth:
            (Math.min(windowHeight - 250, ((windowsWidth - 32) / 3) * 4) / 4) *
            3,
        }}
      >
        <Document
          file={commands}
          options={{ workerSrc: "pdf.worker.js" }}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page
            pageNumber={pageNumber}
            height={Math.min(windowHeight - 250, ((windowsWidth - 32) / 3) * 4)}
            className="as-center"
            loading={
              <div
                style={{
                  minHeight: Math.min(
                    windowHeight - 250,
                    ((windowsWidth - 32) / 3) * 4
                  ),
                }}
              >
                <Loading />
              </div>
            }
            renderAnnotationLayer={false}
          >
            <div
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                flexDirection: "row",
              }}
            >
              <FontAwesomeIcon
                icon={solid("caret-left")}
                onClick={pageNumber <= 1 ? () => {} : previousPage}
                style={{
                  opacity: pageNumber <= 1 ? 0.5 : 1,
                  fontSize:
                    Math.min(
                      windowHeight - 250,
                      ((windowsWidth - 32) / 3) * 4
                    ) / 20,
                  width:
                    Math.min(
                      windowHeight - 250,
                      ((windowsWidth - 32) / 3) * 4
                    ) / 15,
                  height:
                    Math.min(
                      windowHeight - 250,
                      ((windowsWidth - 32) / 3) * 4
                    ) / 15,
                }}
              />
              <FontAwesomeIcon
                icon={solid("caret-right")}
                onClick={pageNumber >= numPages ? () => {} : nextPage}
                style={{
                  opacity: pageNumber >= numPages ? 0.5 : 1,
                  fontSize:
                    Math.min(
                      windowHeight - 250,
                      ((windowsWidth - 32) / 3) * 4
                    ) / 20,
                  width:
                    Math.min(
                      windowHeight - 250,
                      ((windowsWidth - 32) / 3) * 4
                    ) / 15,
                  height:
                    Math.min(
                      windowHeight - 250,
                      ((windowsWidth - 32) / 3) * 4
                    ) / 15,
                }}
              />
            </div>
          </Page>
        </Document>
      </div>

      <a
        href={commands}
        className="c-pointer as-center bg-accent p-16 fw-bold fs-18 ta-center"
        style={{
          color: "white",
          boxShadow: "0px 0px 8px 2px rgba(255, 255, 255, 0.3)",
        }}
        target="_blank"
        rel="noopener noreferrer"
      >
        Download
      </a>
      <div />
      <Backdrop ref={posterRef} blockScroll={false}>
        <img
          src={poster}
          alt="poster"
          width={getWidth(posterSize)}
          height={getHeight(posterSize)}
        />
      </Backdrop>
    </>
  );
};

const PatchNews = () => {
  const { t } = useTranslation();
  const mobile = useMobile();

  return (
    <>
      <Info
        title={t("balanceNews")}
        className={`ta-center ${mobile ? "fs-12" : "fs-24"}`}
      />

      <Info title={t("balanceTitle")}>{t("balanceContent")}</Info>
      <div className="store">
        <div className={`${mobile ? "col-m16" : "row-m16"}`}>
          <img
            src={Thalessa}
            alt="Character torso"
            draggable="false"
            height={200}
            className={`flex as-center`}
          />
          <div className="store">
            <div className="store-name fs-20 my-8">Thalessa</div>

            <div className="fw-bold">{t("musmahhu")}</div>
            <ul>
              <li>{t("musmahhu1")}</li>
              <li>{t("musmahhu2")}</li>
              <li>{t("musmahhu3")}</li>
              <li>
                <span>
                  <span className="fw-bold">{t("musmahhu4")}</span>{" "}
                  <span>{t("musmahhu4.1")}</span>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className={`${mobile ? "col-m16" : "row-m16"}`}>
        <img
          src={Erzbeth}
          alt="Character torso"
          draggable="false"
          height={200}
          className={`flex as-center`}
        />

        <div className="store">
          <div className="store-name fs-20 my-8">Erzsébet</div>

          <div className="fw-bold">{t("draculesti")}</div>
          <ul>
            <li>{t("draculesti1")}</li>
            <li>{t("draculesti2")}</li>
            <li>{t("draculesti3")}</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default News;
