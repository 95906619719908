import NavBarBottom from "components/NavBarBottom";

import "./NavBar.css";
import logo from "images/logo-omen.svg";

const NavBar = ({ ...props }) => {
  return <NavBarBottom logo={logo} {...props} />;
};

export default NavBar;
