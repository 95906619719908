import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { TwitterTweetEmbed } from "react-twitter-embed";

import Loading from "rxs/components/Loading";
import WideContainer from "rxs/components/WideContainer";
import useMobile from "rxs/hooks/useMobile";

import "./Learn.css";

const Learn = () => {
  const mobile = useMobile();
  const { t } = useTranslation();

  return (
    <div className="media">
      <div className={mobile ? "py-32" : "py-128"}>
        <WideContainer containerClassName="f-row fw-wrap jc-center">
          <AsyncTweet tweetId="1528500615348985856" />
          <AsyncTweet tweetId="1506852878685724672" />
          <AsyncTweet tweetId="1504354215422046214" />
          <AsyncTweet tweetId="1501395947162677259" />
          <AsyncTweet tweetId="1439897834434748420" />
          <AsyncTweet tweetId="1439400358019743748" />
          <AsyncTweet tweetId="1545393093305896964" />
          <AsyncTweet tweetId="1521967641304444929" />
          <AsyncTweet tweetId="1438974144385802245" />
          <AsyncTweet tweetId="1407578744441741316" />
        </WideContainer>

        <WideContainer>
          <div className="ta-center bold fs-28 fs-italic">
            {t("learnCopyright")}
          </div>
        </WideContainer>
      </div>
    </div>
  );
};

const AsyncTweet = ({ ...props }) => {
  const [loading, setLoading] = useState(true);
  return (
    <div className="p-16" style={{ width: 400 }}>
      {loading && <Loading />}
      <TwitterTweetEmbed
        options={{ theme: "dark", align: "center" }}
        onLoad={() => setLoading(false)}
        {...props}
      />
    </div>
  );
};

export default Learn;
