import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import NavBar from "omen/components/NavBar";
import Footer from "omen/components/Footer";
import i18n from "i18n";
import { useTranslation } from "react-i18next";
import useMobile from "rxs/hooks/useMobile";
import { ReactComponent as Japan } from "images/japan.svg";

const Main = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const mobile = useMobile();
  return (
    <>
      <div
        className="pe-none"
        style={{
          height: "100vh",
          justifyContent: "flex-end",
          position: "absolute",
          width: "100%",
        }}
      >
        <NavBar
          className="pe-all"
          routeClassName="tt-uppercase"
          routes={[
            { route: "characters", name: t("characters") },
            { route: "media", name: t("media") },
            { route: "learn", name: t("learn") },
            {
              route: "book",
              name: t("bookNow"),
              rightComponent: <Japan width={20} />,
            },
            {
              route: "buy",
              name: t("buyNow"),
              className:
                "buy noselect py-8 bg-accent ta-center " +
                (mobile ? "" : "my--8"),
            },
          ]}
          forceSticky={location.pathname !== "/"}
        />
      </div>
      <div className="f-1">
        <Outlet />
      </div>
      <Footer />
      <div
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          display: "block",
          zIndex: 99999,
          transform: "scale(1.5)",
          transformOrigin: "bottom left",
        }}
      >
        <button style={{ width: 30 }} onClick={() => i18n.changeLanguage("en")}>
          en
        </button>
        <button style={{ width: 30 }} onClick={() => i18n.changeLanguage("jp")}>
          jp
        </button>
      </div>
    </>
  );
};

export default Main;
