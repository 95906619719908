import "./Media.css";
import "./Buy.css";

import cover from "images/special_edition.jpg";

import { ReactComponent as PS5 } from "images/buy/ps5.svg";
import { ReactComponent as PS4 } from "images/buy/ps4.svg";
import { ReactComponent as XboxSeries } from "images/buy/xboxseries.svg";
import { ReactComponent as XboxOne } from "images/buy/xboxone.svg";
import { ReactComponent as Steam } from "images/buy/steam.svg";
import { ReactComponent as Epic } from "images/buy/epic.svg";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import useMobile from "rxs/hooks/useMobile";

const Buy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { t } = useTranslation();
  const mobile = useMobile();
  const [platform, setPlatform] = useState();
  const [url, setUrl] = useState();
  return (
    <>
      <div className="media">
        <div
          className={
            "bg-overlay as-center m-32 " +
            (mobile ? "f-col p-16" : "f-row p-64 ")
          }
        >
          <div
            className={
              "jc-space-between platforms " +
              (mobile ? "f-row fw-wrap mobile" : "f-col")
            }
          >
            <Platform
              className="cl-white-h disabled c-default"
              // className={platform === "ps5" ? "selected" : ""}
              // onClick={() => {
              //   setPlatform("ps5");
              //   setUrl("");
              // }}
              logo={PS5}
            />
            <Platform
              className={platform === "ps4" ? "selected" : ""}
              onClick={() => {
                setPlatform("ps4");
                setUrl(
                  "https://store.playstation.com/en-us/product/UP1979-CUSA07361_00-OMENOFSORROWEEEA"
                );
              }}
              logo={PS4}
            />
            <Platform
              className="cl-white-h disabled c-default"
              // className={platform === "xboxseries" ? "selected" : ""}
              // onClick={() => {
              //   setPlatform("xboxseries");
              //   setUrl("");
              // }}
              logo={XboxSeries}
            />
            <Platform
              className={platform === "xboxone" ? "selected" : ""}
              onClick={() => {
                setPlatform("xboxone");
                setUrl(
                  "https://www.xbox.com/en-US/games/store/omen-of-sorrow/9PPM6VDD4G82"
                );
              }}
              logo={XboxOne}
            />
            <Platform
              className={platform === "steam" ? "selected" : ""}
              onClick={() => {
                setPlatform("steam");
                setUrl(
                  "https://store.steampowered.com/app/457260/Omen_of_Sorrow/"
                );
              }}
              logo={Steam}
            />
            <Platform
              className={platform === "epic" ? "selected" : ""}
              onClick={() => {
                setPlatform("epic");
                setUrl("https://store.epicgames.com/en-US/p/omen-of-sorrow");
              }}
              logo={Epic}
            />
          </div>
          <div
            className={
              " w-100 cover-container " + (mobile ? "mobile pt-16" : "p-32")
            }
          >
            <img
              src={cover}
              alt="cover"
              className="cover of-cover"
              height={!mobile && 700}
            />

            <div className="p-relative">
              <a
                href={url}
                target="_blank"
                rel="noreferrer"
                className={
                  "c-pointer buy noselect " + (mobile ? "mt-16" : "mt-32")
                }
              >
                <div className="bg-accent f-row f-center p-16 tt-uppercase fw-bold">
                  {t("buyNow")}
                </div>
              </a>
              <div
                className={
                  "choose noselect ta-center " + (platform ? "chosen" : "")
                }
              >
                {t("choosePlatform")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Platform = ({ logo: Logo, className = "", ...props }) => {
  const mobile = useMobile();
  return (
    <Logo
      className={"cl-white p-8 c-pointer platform " + className}
      width={mobile ? "calc(50% - 16px)" : "100%"}
      height={mobile ? 40 : 80}
      {...props}
    />
  );
};

export default Buy;
