import useScrollbarSize from "react-scrollbar-size";

import WideContainer from "rxs/components/WideContainer";
import "./Media.css";

import thumbnail1 from "images/media/thumbnail1.jpg";
import thumbnail2 from "images/media/thumbnail2.jpg";
import thumbnail3 from "images/media/thumbnail3.jpg";
import thumbnail4 from "images/media/thumbnail4.jpg";
import thumbnail5 from "images/media/thumbnail5.jpg";
import thumbnail6 from "images/media/thumbnail6.jpg";

import s01 from "images/media/s01.jpg";
import s02 from "images/media/s02.jpg";
import s03 from "images/media/s03.jpg";
import s04 from "images/media/s04.jpg";
import s05 from "images/media/s05.jpg";
import s06 from "images/media/s06.jpg";
import s07 from "images/media/s07.jpg";
import s08 from "images/media/s08.jpg";
import s09 from "images/media/s09.jpg";

import ts01 from "images/media/ts01.jpg";
import ts02 from "images/media/ts02.jpg";
import ts03 from "images/media/ts03.jpg";
import ts04 from "images/media/ts04.jpg";
import ts05 from "images/media/ts05.jpg";
import ts06 from "images/media/ts06.jpg";
import ts07 from "images/media/ts07.jpg";
import ts08 from "images/media/ts08.jpg";
import ts09 from "images/media/ts09.jpg";

import p01 from "images/media/p01.jpg";
import p02 from "images/media/p02.jpg";

import tp01 from "images/media/tp01.jpg";
import tp02 from "images/media/tp02.jpg";

import { useEffect, useRef, useState } from "react";
import useWindowSize from "rxs/hooks/useWindowSize";

import arrow from "images/Arrow.png";
import cat from "samples/cat1.jpg";
import cat2 from "samples/cat2.jpg";
import useMobile from "rxs/hooks/useMobile";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Backdrop from "components/Backdrop";

const media = [
  {
    thumbnail: thumbnail1,
    url: "JDugGClS_8g",
    title: "Omen of Sorrow Limited Edition Trailer (Switch/PS5)",
  },
  {
    thumbnail: thumbnail2,
    url: "LPcDFZ89gHY",
    title: "Omen of Sorrow Trailer (Xbox One)",
  },
  {
    thumbnail: thumbnail3,
    url: "U4Su2ENHlIM",
    title: "Straight Terror - Omen of Sorrow [Official Video] 2018",
  },
  {
    thumbnail: thumbnail4,
    url: "cLhJoc8b-rU",
    title: "Omen of Sorrow Release Trailer",
  },
  {
    thumbnail: thumbnail5,
    url: "jUMBGTXsF5U",
    title: "Omen of Sorrow - Launch Date Announcement Trailer",
  },
  {
    thumbnail: thumbnail6,
    url: "tjjmFEkogYk",
    title: "Omen of Sorrow - Quasimodo Reveal - ESRB",
  },
  { thumbnail: ts01, media: s01, width: 1920, height: 1080 },
  { thumbnail: ts02, media: s02, width: 1920, height: 1080 },
  { thumbnail: ts03, media: s03, width: 1920, height: 1080 },
  { thumbnail: ts04, media: s04, width: 1920, height: 1080 },
  { thumbnail: ts05, media: s05, width: 1920, height: 1080 },
  { thumbnail: ts06, media: s06, width: 1920, height: 1080 },
  { thumbnail: ts07, media: s07, width: 1920, height: 1080 },
  { thumbnail: ts08, media: s08, width: 1920, height: 1080 },
  { thumbnail: ts09, media: s09, width: 1920, height: 1080 },
  { thumbnail: tp01, media: p01, width: 1296, height: 1920 },
  { thumbnail: tp02, media: p02, width: 1296, height: 1920 },
];

const Media = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [mediaVisible, setMediaVisible] = useState(false);
  const [windowsWidth, windowHeight] = useWindowSize();

  const [mediaSize, setMediaSize] = useState({ width: 800, height: 450 });

  const mobile = useMobile();

  const backdropRef = useRef();

  const updateWidthMediaWidth = async () => {
    await new Promise(r => setTimeout(r, 100));

    const diffX = mobile ? 0 : 128;
    const diffY = mobile ? 48 : 128;

    let width = windowsWidth - diffX;
    let height = (width / 16) * 9;
    if (height + diffY > windowHeight) {
      height = windowHeight - diffY;
      width = (height / 9) * 16;
    }

    setMediaSize({ width, height });
  };

  useEffect(() => {
    updateWidthMediaWidth();
  }, [windowsWidth, windowHeight]);

  const [selected, _setSelected] = useState();

  const setSelected = v => {
    if (v === selected) {
      backdropRef.current.show();
    } else {
      _setSelected(v);
    }
  };

  useEffect(() => {
    if (!isNaN(selected)) {
      console.log("selected");
      backdropRef.current.show();
    }
  }, [selected]);

  const nextMedia = e => {
    e.stopPropagation();
    setSelected(Math.min(selected + 1, media.length - 1));
  };

  const prevMedia = e => {
    e.stopPropagation();
    setSelected(Math.max(selected - 1, 0));
  };

  const getWidth = media => {
    const diffX = mobile ? 0 : 128;
    const diffY = mobile ? 48 : 128;

    if (media.width > media.height) {
      let width = windowsWidth - diffX;
      let height = (width / media.width) * media.height;
      if (height + diffY > windowHeight) {
        height = windowHeight - diffY;
        width = (height / media.height) * media.width;
      }

      return width;
    } else {
      let height = windowHeight - diffY;
      let width = (height / media.height) * media.width;
      if (width + diffX > windowsWidth) {
        width = windowsWidth - diffX;
        height = (width / media.width) * media.height;
      }
      return width;
    }
  };

  const getHeight = media => {
    const diffX = mobile ? 0 : 128;
    const diffY = mobile ? 48 : 128;

    if (media.width > media.height) {
      let width = windowsWidth - diffX;
      let height = (width / media.width) * media.height;
      if (height + diffY > windowHeight) {
        height = windowHeight - diffY;
        width = (height / media.height) * media.width;
      }

      return height;
    } else {
      let height = windowHeight - diffY;
      let width = (height / media.height) * media.width;
      if (width + diffX > windowsWidth) {
        width = windowsWidth - diffX;
        height = (width / media.width) * media.height;
      }
      return height;
    }
  };

  return (
    <>
      <div className="media">
        <WideContainer className={"px-32 " + (mobile ? "py-32" : "py-128")}>
          <div
            className="grid"
            style={{
              gridTemplateColumns: "repeat(auto-fill, minmax(310px, 1fr))",
              gap: "24px",
              gridAutoRows: "min-content",
            }}
          >
            {media.map((v, i) => (
              <Content key={i} {...v} onClick={() => setSelected(i)} />
            ))}
          </div>
        </WideContainer>
      </div>
      <Backdrop ref={backdropRef}>
        {!isNaN(selected) && (
          <div style={{ width: "calc(100% - 128px)" }}>
            <div className="f-row as-center">
              <img
                src={arrow}
                className={`t-s--1 arrow as-center noselect ${
                  selected === 0 ? "hidden" : ""
                }`}
                style={{
                  width: mobile ? 32 : 64,
                  position: "absolute",
                  left: 0,
                }}
                alt="arrow"
                onClick={prevMedia}
                draggable={false}
              />
              <div className="p-relative">
                {media[selected].url ? (
                  <iframe
                    key={selected}
                    width={mediaSize.width}
                    height={mediaSize.height}
                    src={`https://www.youtube.com/embed/${media[selected].url}?autoplay=1`}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className="bg-black noselect"
                  />
                ) : (
                  <img
                    alt="media"
                    src={media[selected].media}
                    width={getWidth(media[selected])}
                    height={getHeight(media[selected])}
                  />
                )}
                <div
                  className="p-absolute pe-none"
                  style={{
                    right: 0,
                    top: -24,
                  }}
                >
                  <FontAwesomeIcon className="zi-2" icon={solid("xmark")} />
                </div>

                <div
                  className="p-absolute"
                  style={{
                    right: 0,
                    bottom: -24,
                  }}
                >
                  <div className="text-shadow">{`${selected + 1} of ${
                    media.length
                  }`}</div>
                </div>
              </div>
              <img
                src={arrow}
                className={`arrow as-center noselect ${
                  selected === media.length - 1 ? "hidden" : ""
                }`}
                style={{
                  width: mobile ? 32 : 64,

                  position: "absolute",
                  right: 0,
                }}
                alt="arrow"
                onClick={nextMedia}
                draggable={false}
              />
            </div>
          </div>
        )}
      </Backdrop>
    </>
  );
};

const Content = ({ thumbnail, url, title, onClick = e => {} }) => {
  const handleClick = e => {
    e.preventDefault();
    onClick(e);
  };

  const ref = useRef();

  return (
    <a
      href={"https://www.youtube.com/watch?v=" + url}
      className="c-pointer content of-hidden relative"
      onClick={handleClick}
      style={{
        ...(ref.current ? { height: (ref.current.offsetWidth / 16) * 9 } : {}),
      }}
    >
      <img
        src={thumbnail}
        alt="thumbnail"
        width="100%"
        className="thumbnail"
        ref={ref}
        style={{
          objectFit: "cover",
          ...(ref.current
            ? { height: (ref.current.offsetWidth / 16) * 9 }
            : {}),
        }}
      />
      {title ? (
        <div
          className="p-absolute ta-center w-100 fw-bold text-shadow fs-12"
          style={{ bottom: 10 }}
        >
          {title}
        </div>
      ) : (
        <></>
      )}
      <div className="full-rect">
        <FontAwesomeIcon
          className="zi-2 icon"
          icon={url ? solid("film") : solid("image")}
          style={{ height: 64 }}
        />
      </div>
    </a>
  );
};

export default Media;
