import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import "./Backdrop.css";

const Backdrop = forwardRef(
  ({ showOnStart = false, blockScroll = true, children, ...props }, ref) => {
    useImperativeHandle(ref, () => ({
      show,
      hide,
    }));

    const divRef = useRef();

    const [visible, setVisible] = useState(false);

    useEffect(() => {
      if (showOnStart) {
        show();
      }
    }, []);

    useEffect(() => {
      if (visible) {
        if (blockScroll) {
          document.documentElement.classList.add("noscroll");
        }
        document.addEventListener("mousedown", checkOutside);
      } else {
        if (blockScroll) {
          document.documentElement.classList.remove("noscroll");
        }
        document.removeEventListener("mousedown", checkOutside);
      }
    }, [visible]);

    const show = () => {
      setVisible(true);
    };
    const hide = () => {
      setVisible(false);
    };

    const checkOutside = event => {
      if (divRef.current === event.target) {
        hide();
      }
    };

    return (
      <>
        {visible && (
          <div ref={divRef} className="backdrop f-center" {...props}>
            {children}
          </div>
        )}
      </>
    );
  }
);

export default Backdrop;
