import { useEffect, useRef, useState } from "react";

const useFillScale = maxScale => {
  const node = useRef();
  const container = useRef();
  const [scale, setScale] = useState(1);

  useEffect(() => {
    const handleResize = async () => {
      await new Promise(r => setTimeout(r, 100));
      if (node.current && container.current) {
        const horizontalScale =
          container.current.offsetWidth / node.current.offsetWidth;
        const verticalScale =
          container.current.offsetHeight / node.current.offsetHeight;
        let scale = Math.min(horizontalScale, verticalScale);
        if (!isNaN(maxScale)) {
          scale = Math.min(scale, maxScale);
        }
        setScale(scale);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return [node, container, scale];
};

export default useFillScale;
