// import { createRef } from "react";
import axios from "axios";

import { domain } from "./constants";
// import { getAccessToken, getRefreshToken, setAccessToken } from "./utils";

// export const authContextRef = createRef();

const backend = axios.create();

// const getToken = async () => {
//   let token;
//   try {
//     const refreshToken = await getRefreshToken();
//     let response = await axios.post(`${domain}/user/token`, {
//       refreshToken,
//     });
//     token = response.data.token;
//   } catch (err) {
//     console.log(err);
//   }
//   return token;
// };

backend.interceptors.request.use(
  async config => {
    // const accessToken = await getAccessToken();
    // if (accessToken) {
    //   config.headers["Authorization"] = `Bearer ${accessToken}`;
    // }
    // if (!config._retry) {
    config.url = `${domain}/api/${config.url}`;
    // }
    return config;
  },
  error => Promise.reject(error)
);

// backend.interceptors.response.use(
//   response => response,
//   async error => {
//     const config = error.config;
//     const refreshToken = getRefreshToken();
//     if (!refreshToken || (error.response.status === 401 && config._retry)) {
//       authContextRef.current?.logout();
//       return Promise.reject(error);
//     } else if (error.response.status !== 401) {
//       return Promise.reject(error);
//     } else {
//       config._retry = true;
//       const accessToken = await getToken();
//       setAccessToken(accessToken);
//       return backend(config);
//     }
//   }
// );

export default backend;
