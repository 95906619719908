import { useEffect, useState } from "react";

const useShort = (shortHeight = 760) => {
  const [short, setShort] = useState();

  useEffect(() => {
    const handleShort = () => {
      setShort(window.innerHeight <= shortHeight);
    };
    window.addEventListener("resize", handleShort);
    handleShort();
    return () => window.removeEventListener("resize", handleShort);
  }, [shortHeight]);

  return short;
};

export default useShort;
