import RXSFooter from "rxs/components/Footer";
import {
  AOne,
  Omen,
  ESRB,
  PS4,
  PS5,
  XBOXOne,
  XBOXSeries,
  Steam,
  Epic,
  Switch,
  Taito,
  Facebook,
  Twitter,
  Instagram,
} from "Logos";
import useMobile from "rxs/hooks/useMobile";

import "./Footer.css";
import useFillScale from "rxs/hooks/useFillScale";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  const mobile = useMobile();
  const mobileM = useMobile(1400);
  const mobileS = useMobile(400);
  return (
    <RXSFooter
      maxWidth="1920px"
      className={`px-32 ${mobile ? "pt-64" : "pt-128"}`}
    >
      <div className={`${mobileM ? "col-m32" : "row-m32"} mnw-0`}>
        <Logo
          logo={ESRB}
          name="esrb"
          style={{
            height: mobileM ? 75 : 100,
          }}
          className="as-center"
        />

        <div className={`f-row fw-wrap jc-center g-${mobileS ? 16 : 32} f-1`}>
          <div className={`row-m${mobileS ? 16 : 32} f-center mnw-0`}>
            <Logo logo={PS4} name="ps4" className="mnw-0" />
            <Logo logo={PS5} name="ps5" className="mnw-0" />
          </div>

          <div className={`row-m${mobileS ? 16 : 32} f-center mnw-0`}>
            <Logo logo={XBOXOne} name="xbox one" className="mnw-0" />
            <Logo logo={XBOXSeries} name="xbox series" className="mnw-0" />
          </div>

          <div className={`row-m${mobileS ? 16 : 32} f-center mnw-0`}>
            <Logo logo={Switch} name="switch" className="mnw-0" />
            {/* <Logo logo={Taito} name="taito" className="mnw-0" /> */}
          </div>

          <div className={`row-m${mobileS ? 16 : 32} f-center mnw-0`}>
            <Logo logo={Steam} name="steam" className="mnw-0" />
            <Logo logo={Epic} name="epic" className="mnw-0" />
          </div>
        </div>

        <a
          href="https://aonegames.com"
          target="_blank"
          rel="noreferrer"
          className="as-center"
        >
          <Logo
            logo={AOne}
            name="aone"
            style={{ height: mobileM ? 75 : 100 }}
          />
        </a>
      </div>
      <div className={`separator ${mobile ? "mt-32" : "mt-64"}`} />
      <div className={`m-32 f-center ${mobile ? "col-m32 mb-64" : "row-m32"}`}>
        <div className="fs-40 ta-center">{t("follow")}</div>
        <a
          href="https://www.facebook.com/aonegamespage"
          target="_blank"
          rel="noreferrer"
        >
          <Logo logo={Facebook} name="facebook" />
        </a>

        <a
          href="https://twitter.com/aonegames"
          target="_blank"
          rel="noreferrer"
        >
          <Logo logo={Twitter} name="twitter" />
        </a>
        {/* <a href="" target="_blank" rel="noreferrer">
          <Logo logo={Instagram} name="instagram" />
        </a> */}
      </div>
    </RXSFooter>
  );
};

const Logo = ({ logo, name, style = {}, ...props }) => (
  <img
    src={logo}
    alt={name}
    {...props}
    style={{ objectFit: "contain", ...style }}
  />
);

export default Footer;
