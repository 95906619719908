import React, { useEffect, useRef, useState } from "react";
import WideContainer from "rxs/components/WideContainer";
import LoremIpsum from "react-lorem-ipsum";
import { ParallaxBanner } from "react-scroll-parallax";

import title from "images/Titulo.jpg";
import titleMobile from "images/special_edition.jpg";
import image1 from "images/01.jpg";
import image2 from "images/02.jpg";
import image3 from "images/03.jpg";
import image4 from "images/04.jpg";
import { ReactComponent as Omen } from "images/logo-omen.svg";

import "./Home.css";
import useMobile from "rxs/hooks/useMobile";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import News from "News";

const Home2 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { t } = useTranslation();
  const mobile = useMobile();

  const [newsVisible, setNewsVisible] = useState(true);
  return (
    <>
      <div
        style={{
          height: "100vh",
          backgroundImage: `url(${mobile ? titleMobile : title})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: mobile ? "cover" : "100% auto",
          backgroundPosition: "center",
          justifyContent: "flex-end",
        }}
      >
        {mobile && (
          <div className="overlay">
            {" "}
            <Omen className="omen" />{" "}
          </div>
        )}

        <Link
          to="buy"
          className="tt-uppercase fw-bold bg-accent buy noselect as-center mb-128 py-16 px-32 zi-1"
        >
          {t("buyNow")}
        </Link>
      </div>
      <SideInfo image={image1} left className="py-192 ta-justify">
        <div className="fs-20">{t("home1")}</div>
      </SideInfo>
      <SideInfo image={image2} right className="py-192 ta-justify">
        <div className="fs-20">{t("home2")}</div>
      </SideInfo>
      <SideInfo image={image3} left className="py-192 ta-justify">
        <div className="fs-28 fw-bold tt-uppercase mcb-0">
          {t("home3Title")}
        </div>
        <div className="fs-20">{t("home3")}</div>
      </SideInfo>
      <SideInfo image={image4} right className="py-192 ta-justify">
        <div className="fs-28 fw-bold tt-uppercase mcb-0">
          {t("home4Title")}
        </div>
        <div className="fs-20">{t("home4")}</div>
      </SideInfo>

      {newsVisible && <News />}
    </>
  );
};

const SideInfo = ({ image, left, right, className = "", children }) => {
  const mobile = useMobile();
  return (
    <ParallaxBanner
      layers={[{ image: image, speed: -35 }]}
      style={{ height: "min-content" }}
    >
      <WideContainer
        style={{ zIndex: 99 }}
        className={`parallax-gradient ${right ? "right" : left ? "left" : ""}`}
      >
        <div className="p-32">
          <div
            className={`${mobile ? "w-100" : "w-50"} ${
              right ? "as-end" : left ? "as-start" : ""
            } ${className}`}
          >
            {children}
          </div>
        </div>
      </WideContainer>
    </ParallaxBanner>
  );
};

export default Home2;
