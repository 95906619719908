import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  gbr,
  arc,
  grg,
  mmy,
  wlf,
  qsm,
  hyd,
  frk,
  drc,
  erz,
  zfk,
  tls,
} from "./Avatar";
import "./Characters.css";
import {
  Gabriel,
  Arctorius,
  Radegonda,
  Imhotep,
  Caleb,
  Quasimodo,
  Hyde,
  Adam,
  Vladislav,
  Erzbeth,
  Zafkiel,
  Thalessa,
} from "./Torso";

import arrow from "images/Arrow.png";
import selector from "images/characters/misc/Player1_Select.png";
import useMobile from "rxs/hooks/useMobile";
import useWindowSize from "rxs/hooks/useWindowSize";
import useShort from "rxs/hooks/useShort";
import useFillScale from "rxs/hooks/useFillScale";

const characters = [
  {
    avatar: gbr,
    torso: Gabriel,
    name: "Gabriel",
    biography: "gabrielBio",
  },
  {
    avatar: arc,
    torso: Arctorius,
    name: "Arctorius",
    biography: "arctoriusBio",
  },
  {
    avatar: grg,
    torso: Radegonda,
    name: "Radegonda",
    biography: "radegondaBio",
  },
  {
    avatar: mmy,
    torso: Imhotep,
    name: "Imhotep",
    biography: "imhotepBio",
  },
  {
    avatar: wlf,
    torso: Caleb,
    name: "Caleb",
    biography: "calebBio",
  },
  {
    avatar: qsm,
    torso: Quasimodo,
    name: "Quasimodo",
    biography: "quasimodoBio",
  },
  {
    avatar: hyd,
    torso: Hyde,
    name: "Hyde",
    biography: "hydeBio",
  },
  {
    avatar: frk,
    torso: Adam,
    name: "Adam",
    biography: "adamBio",
  },
  {
    avatar: drc,
    torso: Vladislav,
    name: "Vladislav III",
    biography: "vladislavBio",
  },
  {
    avatar: erz,
    torso: Erzbeth,
    name: "Erzbeth",
    biography: "erzbethBio",
  },
  {
    avatar: zfk,
    torso: Zafkiel,
    name: "Zafkiel",
    biography: "zafkielBio",
  },
  {
    avatar: tls,
    torso: Thalessa,
    name: "Thalessa",
    biography: "thalessaBio",
  },
];

const Characters = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [character, setCharacter] = useState(0);
  const [selectorPosition, setSelectorPosition] = useState({ x: -10, y: 0 });
  const mobile = useMobile();
  const mobileM = useMobile(1100);

  const [windowWidth, windowHeight] = useWindowSize();
  const short = useShort(1000);

  const [topRef, topContainerRef, topScale] = useFillScale(1);
  const [bottomRef, bottomContainerRef, bottomScale] = useFillScale();

  const verticalBottom =
    mobile ||
    bottomContainerRef.current?.offsetHeight >
      bottomContainerRef.current?.offsetWidth;

  useEffect(() => {
    setSelectorPosition({
      x: 116 * Math.floor(character / (mobile ? 3 : 2)) - 10,
      y: 101 * (character % (mobile ? 3 : 2)),
    });
  }, [windowWidth, windowHeight]);

  useEffect(() => {
    setSelectorPosition({
      x: 116 * Math.floor(character / (mobile ? 3 : 2)) - 10,
      y: 101 * (character % (mobile ? 3 : 2)),
    });
  }, [character, windowWidth, windowHeight]);
  return (
    <div className="characters px-64 parallax-gradient bottom pt-16">
      <div
        ref={topContainerRef}
        className="f-center"
        style={{
          height: "30%",
        }}
      >
        <div
          ref={topRef}
          className="grid as-center selector"
          style={{
            gridAutoFlow: "column",
            gridTemplateRows:
              "min-content min-content" + (mobile ? " min-content" : ""),
            transform: `scale(${topScale})`,
          }}
        >
          {characters.map((v, i) => (
            <Avatar
              key={i}
              character={v}
              onClick={() => setCharacter(i)}
              className={`${character === i ? "selected" : ""}`}
            />
          ))}
          <img
            src={selector}
            alt="selector"
            style={{
              position: "absolute",
              transform: `translate(${selectorPosition.x}px, ${selectorPosition.y}px)`,
              transition: "all 0.2s",
            }}
          />
        </div>
      </div>
      <div
        className="character jc-end ai-center"
        ref={bottomContainerRef}
        style={{
          height: "70%",
        }}
      >
        <div
          style={{
            transform: `scale(${bottomScale})`,
            transformOrigin: "50% 100%",
          }}
          ref={bottomRef}
        >
          <div
            className={`${verticalBottom ? "" : "f-row"}`}
            style={{ margin: mobileM ? "0 -144px" : "" }}
          >
            <div
              className="torso-container"
              style={{
                marginLeft: verticalBottom ? 48 : 0,
              }}
            >
              {characters.map((v, i) => (
                <Torso key={i} character={v} active={character === i} />
              ))}
            </div>

            <Biography
              character={characters[character]}
              onLeftClick={() => setCharacter(p => Math.max(p - 1, 0))}
              onRightClick={() =>
                setCharacter(p => Math.min(p + 1, characters.length - 1))
              }
              hideLeftArrow={character === 0}
              hideRightArrow={character === characters.length - 1}
              style={{
                marginTop: verticalBottom ? -128 : 32,
                zIndex: mobile ? 1 : 0,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Avatar = ({ character, className = "", ...props }) => {
  const { avatar } = character;
  return (
    <div
      className={`c-pointer avatar ${className}`}
      style={{
        width: 136,
        height: 101,
        backgroundImage: `url(${avatar})`,
        backgroundRepeat: "no-repeat",
        margin: "0 -10px",
      }}
      {...props}
    />
  );
};

const Torso = ({ character, active = false }) => {
  const { torso } = character;
  return (
    <img
      src={torso}
      alt="Character torso"
      draggable="false"
      className={`torso ${active ? "active" : 0}`}
    />
  );
};

const Biography = ({
  character,
  onLeftClick = () => {},
  onRightClick = () => {},
  hideLeftArrow = false,
  hideRightArrow = false,
  ...props
}) => {
  const { name, biography } = character;
  const { t, i18n } = useTranslation();
  return (
    <div className="biography f-center" {...props}>
      <div className="cinzel fw-bold">
        <div className={`fs-48 ta-center`}>{name}</div>
        <div className="f-row f-center">
          <img
            className={`t-s--1 arrow noselect ${hideLeftArrow ? "hidden" : ""}`}
            src={arrow}
            alt="arrow"
            onClick={onLeftClick}
            draggable={false}
          />
          <div className={`fs-${i18n.language === "jp" ? 12 : 16} ta-justify`}>
            {t(biography)}
          </div>
          <img
            className={`arrow noselect ${hideRightArrow ? "hidden" : ""}`}
            src={arrow}
            alt="arrow"
            onClick={onRightClick}
            draggable={false}
          />
        </div>
      </div>
    </div>
  );
};

export default Characters;
