import validator from "validator";

import "./Media.css";
import "./Book.css";

import cover from "images/special_edition.jpg";

import { ReactComponent as PS5 } from "images/buy/ps5.svg";
import { ReactComponent as PS4 } from "images/buy/ps4.svg";
import { ReactComponent as XboxSeries } from "images/buy/xboxseries.svg";
import { ReactComponent as XboxOne } from "images/buy/xboxone.svg";
import { ReactComponent as Steam } from "images/buy/steam.svg";
import { ReactComponent as Epic } from "images/buy/epic.svg";
import { ReactComponent as Switch } from "images/buy/switch.svg";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import useMobile from "rxs/hooks/useMobile";
import backend from "backend";

const Book = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { t } = useTranslation();
  const mobile = useMobile();
  const [platforms, setPlatforms] = useState([]);
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [success, setSucess] = useState(false);

  const handleSelected = platform => {
    setPlatforms(p =>
      p.includes(platform) ? p.filter(v => v !== platform) : [...p, platform]
    );
  };

  const submit = async () => {
    try {
      setError(false);
      // if (!platforms.length) {
      //   throw new Error("No platforms selected!");
      // }
      if (!validator.isEmail(email)) {
        setError(true);
        throw new Error("Invalid email!");
      }
      backend.post("booking", { email, platforms });
      setSucess(true);
      await new Promise(r => setTimeout(r, 1000));
      setSucess(false);
      setEmail("");
      setPlatforms([]);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="media">
        <div
          className={"bg-overlay as-center m-32 " + (mobile ? "p-16" : "p-64")}
        >
          <div className={mobile ? "f-col" : "f-row"}>
            <div className="f-col">
              <div
                className="ta-center f-1 f-center py-16 tt-uppercase fw-bold fs-24 noselect"
                style={{ minWidth: 240 }}
              >
                {t("choosePlatform")}
              </div>

              <div
                className={
                  "jc-space-between platforms " +
                  (mobile ? "f-row fw-wrap mobile" : "f-col")
                }
              >
                <Platform
                  className={platforms.includes("ps4") ? "selected" : ""}
                  onClick={() => handleSelected("ps4")}
                  logo={PS4}
                />
                <Platform
                  className={platforms.includes("ps5") ? "selected" : ""}
                  onClick={() => handleSelected("ps5")}
                  logo={PS5}
                />

                <Platform
                  className={platforms.includes("steam") ? "selected" : ""}
                  onClick={() => handleSelected("steam")}
                  logo={Steam}
                />
                <Platform
                  className={platforms.includes("switch") ? "selected" : ""}
                  onClick={() => handleSelected("switch")}
                  logo={Switch}
                />
              </div>
            </div>
            <div
              className={
                " w-100 cover-container " + (mobile ? "mobile pt-16" : "pl-32")
              }
            >
              <img
                src={cover}
                alt="cover"
                className="cover of-cover noselect"
                height={!mobile && 460}
              />
            </div>
          </div>
          {error && (
            <div className="error ta-center p-8 mt-16 noselect">
              {t("invalidEmail")}
            </div>
          )}
          <input
            onChange={e => setEmail(e.target.value)}
            value={email}
            placeholder={t("emailAddress")}
            type="text"
            className="email mt-16"
            autoComplete="off"
          />
          <div className="p-relative">
            <div
              className={
                "c-pointer buy noselect " + (mobile ? "mt-16" : "mt-16")
              }
            >
              <div
                className="bg-accent f-row f-center p-16 tt-uppercase fw-bold"
                onClick={submit}
              >
                {t("bookNow")}
              </div>
            </div>
            {/* <div
              className={
                "choose noselect ta-center " +
                (platforms.length ? "chosen" : "")
              }
            >
              {t("choosePlatform")}
            </div> */}
          </div>
          {success && (
            <div className="bg-overlay success">
              <FontAwesomeIcon icon={solid("check")} size="10x" bounce />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const Platform = ({ logo: Logo, className = "", ...props }) => {
  const mobile = useMobile();
  return (
    <Logo
      className={"cl-white p-8 c-pointer platform " + className}
      width={mobile ? "calc(50% - 16px)" : "100%"}
      height={mobile ? 40 : 80}
      {...props}
    />
  );
};

export default Book;
